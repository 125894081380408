<template>
  <a-card bordered>
    <a-row :gutter="8">
      <!--<a-col :xs="24" :sm="24" :md="12" :lg="8" :xl="4" :xxl="3">
        <a-input placeholder="Số điện thoại" v-model="queryParam.customerPhone" allowClear />
      </a-col>
      <a-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="2">
        <a-button :style="{ width: '100%' }" icon="search" type="primary" @click="getData">Xem dữ liệu</a-button>
      </a-col>-->
      <a-col>
        <h1 class="vip-customer">Khách hàng VIP</h1>
      </a-col>
      <a-col :xs="12" :sm="12" :md="12" :lg="4" :xl="4" :xxl="2">
        <a-button
          v-if="$can($permission.add, $route.path)"
          :style="{ width: '100%' }"
          icon="user-add"
          @click="handleAddItem"
          >Thêm mới</a-button
        >
      </a-col>
    </a-row>
    <a-row :gutter="8" style="margin-top: 20px">
      <a-col>
        <a-table
          :columns="columns"
          :data-source="data"
          :rowKey="(record, index) => index"
          :loading="loading"
          size="small"
          :pagination="false"
          bordered
        >
          <span slot="sendDate" slot-scope="text, record">{{ record.sendDate | formatDate }}</span>
          <span slot="action" slot-scope="text, record">
            <!--<a-icon v-if="$can($permission.edit, $route.path)" type="edit" @click="handleEdit(record)" />
            <a-divider type="vertical" />-->
            <a-icon v-if="$can($permission.delete, $route.path)" type="delete" @click="handleDelete(record)" />
          </span>
        </a-table>
        <pagination
          v-show="total > 0"
          :total="total"
          :page.sync="queryParam.page"
          :limit.sync="queryParam.limit"
          @pagination="getData"
        />
      </a-col>
    </a-row>
    <VipCustomerAddDialog v-if="visibleAddItem" :visible.sync="visibleAddItem" @realoadData="handleAddSuccess" />
    <!--<CustomerSpecialUpdateDialog
      v-if="visibleUpdateItem"
      :visible.sync="visibleUpdateItem"
      :item="itemUpdate"
      @realoadData="handleAddSuccess"
    />-->
  </a-card>
</template>

<script>
import VipCustomerAddDialog from './VipCustomerAddDialog'
// import CustomerSpecialUpdateDialog from './CustomerSpecialUpdateDialog'
import Pagination from '@/components/Pagination'
import Utils from '@/utils/others'
import { RepositoryFactory } from '@/api/RepositoryFactory'
const CustomerSpecialRepository = RepositoryFactory.get('customerSpecial')
const ErrorCutReasonRepo = RepositoryFactory.get('errorCutReason')
const SalonRepo = RepositoryFactory.get('salon')
const VipCustomerRepository = RepositoryFactory.get('vipCustomer')

export default {
  components: { VipCustomerAddDialog, Pagination },
  data() {
    return {
      fromDate: undefined,
      toDate: undefined,
      queryParam: {
        page: 1,
        limit: 10
      },
      total: 0,
      loading: false,
      columns: [
        {
          title: 'STT',
          dataIndex: 'order'
        },
        {
          title: 'Khách hàng',
          dataIndex: 'customerName'
        },
        {
          title: 'Điện thoại',
          dataIndex: 'phone'
        },
        {
          title: 'Giảm giá DV',
          dataIndex: 'discountServices'
        },
        {
          title: 'Giảm giá SP',
          dataIndex: 'discountCosmetic'
        },
        {
          title: 'Hành động',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],

      reasonOptions: [],
      statusOptions: [
        {
          id: 1,
          text: 'Chưa duyệt'
        },
        {
          id: 2,
          text: 'Đã duyệt'
        },
        {
          id: 3,
          text: 'Không duyệt'
        }
      ],
      salonOptions: [],
      errorCutReasonOptions: [],
      visibleAddItem: false,
      visibleUpdateItem: false,
      itemUpdate: undefined
    }
  },
  methods: {
    handleChangeFromDate(date, dateString) {
      this.fromDate = dateString
    },

    handleChangeToDate(date, dateString) {
      this.toDate = dateString
    },

    async getData() {
      this.loading = true
      this.data = []

      try {
        const response = await VipCustomerRepository.getList(this.queryParam)

        /*this.data = response.results.map((item, i) => ({
          ...item,
          id: i + 1
        }))*/
        this.data = response.results
        this.total = response.total
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    handleAddItem() {
      this.visibleAddItem = true
    },
    handleEdit(record) {
      this.indexOf = record
      this.visibleUpdateItem = true
    },
    async handleDelete(record) {
      try {
        const isConfirm = await Utils.confirm(this.$confirm, {
          title: 'Xác nhận',
          content: 'Bạn có chắc chắn muốn xóa khách hàng này không?'
        })
        if (!isConfirm) {
          return
        }
        await CustomerSpecialRepository.delete(record.id)
        this.$message.success('Xóa khách hàng thành công.')
        await this.getData()
      } catch (error) {
        console.log(error)
      }
    },

    async getErrorCutReason() {
      const response = await ErrorCutReasonRepo.getListSelect()
      this.errorCutReasonOptions = response
    },

    async getSalon() {
      try {
        const response = await SalonRepo.getListSelect()
        this.salonOptions = response
      } catch (error) {
        console.log(error)
      }
    },

    handleAddSuccess(state) {
      if (state) {
        this.getData()
      }
    },

    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    }
  },
  created() {
    this.getData()
  }
}
</script>

<style>
.vip-customer {
  font-size: 28px;
}
</style>
