<template>
  <a-modal
    v-model="localVisible"
    title="Thêm mới khách hàng VIP"
    on-ok="handleOk"
    on-cancel="handleCancel"
    :width="this.isMobile() ? '100%' : this.isTablet() ? '70%' : '40%'"
    style="top: 100px"
    :bodyStyle="{ height: '200px', overflowY: 'auto' }"
  >
    <a-row :gutter="8">
      <a-col>
        <label class="form-item-lable"
          >Khách hàng <strong>{{ customerName }}</strong></label
        >
        <a-input placeholder="Nhập điện thoại khách hàng" @blur="handleChangePhone" :maxLength="10" />
      </a-col>
    </a-row>
    <a-row>
      <a-col :md="12">
        <label class="form-item-lable">Giảm giá MP (%)</label>
        <a-input v-model="params.productDiscountPercent" type="number" />
      </a-col>
      <a-col :md="12">
        <label class="form-item-lable">Giảm giá DV (%)</label>
        <a-input v-model="params.serviceDiscountPercent" type="number" />
      </a-col>
    </a-row>
    <template slot="footer">
      <a-button key="back" @click="handleCancel">
        Hủy bỏ
      </a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="onSubmit">
        Lưu lại
      </a-button>
    </template>
  </a-modal>
</template>
<script>
import { mixin, mixinDevice } from '@/utils/mixin'
import moment from 'moment'

import { RepositoryFactory } from '@/api/RepositoryFactory'
const CustomerRepo = RepositoryFactory.get('customerRepository')
const VipCustomerRepository = RepositoryFactory.get('vipCustomer')

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixin, mixinDevice],
  computed: {
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  data() {
    return {
      loading: false,
      data: [],
      fromDate: moment(),
      customerName: undefined,
      params: {
        customerId: undefined,
        typeCustomerSpecialId: 2,
        productDiscountPercent: undefined,
        serviceDiscountPercent: undefined
      }
    }
  },
  methods: {
    handleChangeCutDate(date, dateString) {
      this.params.cutErrorDate = dateString
    },

    handleCancel() {
      this.localVisible = false
    },

    async onSubmit() {
      try {
        if (!this.isValidInput()) {
          return
        }
        await VipCustomerRepository.create(this.params)
        this.$message.success('Thêm mới thành công.')
        this.localVisible = false
        this.$emit('realoadData', true)
      } catch (error) {
        console.log(error)
      }
    },

    isValidInput() {
      if (!this.params.customerId) {
        this.$message.warn('Vui lòng nhập số điện thoại.')
        return false
      }
      if (
        this.params.productDiscountPercent <= 0 ||
        this.params.productDiscountPercent > 100 ||
        this.params.serviceDiscountPercent <= 0 ||
        this.params.serviceDiscountPercent > 100
      ) {
        this.$message.warn('Phần trăm giảm giá phải nằm trong khoảng từ 1 đến 100!')
        return false
      }
      return true
    },

    async handleChangePhone(e) {
      const phone = e.target.value
      if (!phone || (phone && phone.length !== 10)) {
        this.$message.warn('Vui lòng nhập đúng số điện thoại')
        return
      }
      try {
        this.customerName = undefined
        const response = await CustomerRepo.getByPhone(phone)
        this.customerName = response.fullname
        this.params.customerId = response.id
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<style scoped>
.form-item-input {
  margin: 5px 0 5px 0;
  width: 100%;
}
.form-item-lable {
  display: inline-block;
  margin: 5px 0 5px 0;
}
</style>
